import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoColsAnim from "../../../components/Chronology/Modules/ModTwoColsAnim";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 60",
  week: "Semana 9",
  day: "12",
  month: "may",
  monthNumber: "05",
  date: "2020-05-12",
  path: "/cronologia/semana-09#dia-12-may",
};
const Day60 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.377 nuevos positivos y 176 personas fallecidas.
          Permanecen hospitalizadas 123.484 personas, 513 más que el día
          anterior, y 1.841 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.371, lo que supone un aumento de 23 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 51,6 %.
        </ModText>
        <ModText>
          El <strong>Ministerio de Sanidad</strong> ha impulsado una{" "}
          <strong>orden ministerial</strong> que regula las obligaciones y{" "}
          <strong>
            procedimientos de obtención y comunicación de información
          </strong>{" "}
          para la vigilancia epidemiológica relacionada con COVID-19.
        </ModText>
        <ModTwoColsAnim
          src="/images/anim/01_abr_estudio_movilidad.svg"
          alt="datos"
          small={false}
        >
          La nueva orden exige que los <strong>sistemas de información</strong>{" "}
          epidemiológica
          <strong>proporcionen diariamente datos completos y precisos</strong>,
          y para ello, se han han incorporado nuevas variables sobre casos
          confirmados y sospechosos, así como una ampliación del número de
          profesionales de los servicios de vigilancia epidemiológica dedicados
          a la respuesta al COVID-19.
        </ModTwoColsAnim>

        <ModText>
          La nueva orden exige que los <strong>sistemas de información</strong>{" "}
          epidemiológica
          <strong>proporcionen diariamente datos completos y precisos</strong>,
          y para ello, se han han incorporado nuevas variables sobre casos
          confirmados y sospechosos, así como una ampliación del número de
          profesionales de los servicios de vigilancia epidemiológica dedicados
          a la respuesta al COVID-19.
        </ModText>
        <ModText>
          Por otra parte,<strong> Sanidad</strong> también ha establecido
          medidas para todas aquellas personas que procedan del extranjero:
          deberán guardar
          <strong>cuarentena los 14 días</strong> siguientes a su llegada.
        </ModText>
        <ModImage
          src="/images/svg/12_may_cuarentena-fronteras.svg"
          alt="fronteras"
        />
        <ModText>
          El <strong>Ministerio del Interior</strong> ha dictado una Orden en la
          que establece las{" "}
          <strong>restricciones en las fronteras interiore</strong>s en puertos
          y aeropuertos (para vuelos y barcos con origen en países del espacio
          Schengen), que se aplicarán a partir del próximo 15 de mayo.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-4933"
            name="Medidas de vigilancia epidemiológica durante la fase de transición"
          />
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-4932"
            name="Condiciones de cuarentena para personas que procedan de otros países"
          />
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-4929"
            name="Restricciones en las fronteras interiores aéreas y marítimas"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day60;
